import React from 'react';
import {type Editor, type RenderBlockProps} from '@englex/slate-react';
import {type Next} from '@englex/slate';

import {type RendererPlugin} from '../../../interface';
import {isBlockOfType} from '../../../../utils';
import {SlateBlock} from '../../../../interface';

import './DialogTable.scss';

export default class DialogTable implements RendererPlugin {
  public renderBlock = (
    {node, attributes, children}: RenderBlockProps,
    editor: Editor,
    next: Next
  ) => {
    if (!isBlockOfType(node, SlateBlock.DIALOG_TABLE)) {
      return next();
    }

    return (
      <div className="dialog-table" {...attributes}>
        {children}
      </div>
    );
  };
}
