import React from 'react';
import {type Editor, type RenderBlockProps} from '@englex/slate-react';
import classNames from 'classnames';
import {type Next} from '@englex/slate';

import {type RendererPlugin} from '../../../interface';
import {isBlockOfType} from '../../../../utils';
import {SlateBlock} from '../../../../interface';

export default class DialogRow implements RendererPlugin {
  public renderBlock = (
    {node, children, isSelected}: RenderBlockProps,
    editor: Editor,
    next: Next
  ) => {
    if (!isBlockOfType(node, SlateBlock.DIALOG_ROW)) {
      return next();
    }

    const {startBlock, endBlock} = editor.value;

    const blocksOfThisRow = node.getBlocks();
    const rowFocused =
      !editor.readOnly &&
      isSelected &&
      endBlock &&
      startBlock &&
      blocksOfThisRow.contains(endBlock) &&
      blocksOfThisRow.contains(startBlock);

    return (
      <React.Fragment>
        <div
          contentEditable={false}
          className={classNames('row-focused-indicator', {'is-focused': rowFocused})}
        />
        {children}
      </React.Fragment>
    );
  };
}
