import {type Editor as ReactEditor, type RenderBlockProps} from '@englex/slate-react';
import React from 'react';
import {type Editor, type Next} from '@englex/slate';
import classNames from 'classnames';

import type ToolbarButton from 'components/Slate/SlateEditor/plugins/ToolbarButton';

import {type RendererPlugin} from '../../../interface';
import {isBlockOfType} from '../../../../utils';
import {SlateBlock} from '../../../../interface';
import InlineToolbar from './InlineToolbar';

export default class DialogCell implements RendererPlugin {
  private readonly inlineToolbarPlugins?: ToolbarButton[];

  constructor(inlineToolbarPlugins?: ToolbarButton[]) {
    this.inlineToolbarPlugins = inlineToolbarPlugins;
  }

  public renderBlock = (
    {node, attributes, children}: RenderBlockProps,
    editor: ReactEditor & Editor,
    next: Next
  ) => {
    if (!isBlockOfType(node, SlateBlock.DIALOG_CELL)) {
      return next();
    }

    const isSecondCell = editor.value.document.getPreviousSibling(node.key);
    const {readOnly} = editor;
    return (
      <div
        {...attributes}
        className={classNames('dialog-cell', {
          'first-cell': !isSecondCell,
          'second-cell': isSecondCell
        })}
      >
        {isSecondCell && !readOnly && this.inlineToolbarPlugins ? (
          <InlineToolbar editor={editor} plugins={this.inlineToolbarPlugins} />
        ) : null}
        {children}
      </div>
    );
  };
}
