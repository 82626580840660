import React, {useCallback, useContext} from 'react';
import {useSelector} from 'react-redux';

import {type AppState} from 'store/interface';

import {genKey} from '../../Slate/utils';
import {PointerButton} from '../common/PointerButton';
import {MessageType, usePublishPointer} from '../common/usePublishPointer';
import {SelectionContext} from './SelectionContext';
import {SlatePointerFacade} from './SlatePointerFacade';

interface Props {
  exerciseId: string;
}

export function SelectionButton({exerciseId}: Props) {
  const inCall = useSelector<AppState, boolean>(state => Boolean(state.rtc.callInProgress));

  const openExercise = usePublishPointer();

  const {highlightTeacherRange, getSubscriber} = useContext(SelectionContext);

  const onClick = useCallback(() => {
    const subscriber = getSubscriber();

    if (subscriber?.range) {
      if (!inCall && Boolean(import.meta.env.REACT_APP_POINTER_DEV_MODE_ENABLED)) {
        return new Promise(resolve => {
          const callback = highlightTeacherRange();

          setTimeout(() => resolve(callback()), 300);
        });
      }

      const widgetProps = SlatePointerFacade.getWidgetProps(subscriber.editor);

      if (widgetProps) {
        const {widget} = widgetProps;

        const data = {
          exerciseId,
          widgetId: widget?.id,
          editorId: subscriber.id,
          range: subscriber.range,
          elementId: genKey()
        };

        return openExercise(MessageType.Selection, data, highlightTeacherRange());
      }
    }

    return Promise.resolve(true);
  }, [inCall, exerciseId, openExercise, highlightTeacherRange, getSubscriber]);

  return <PointerButton onClick={onClick} />;
}
