import React, {useCallback, useState} from 'react';
import {defineMessages, FormattedMessage, useIntl} from 'react-intl';
import Button from 'react-bootstrap/lib/Button';
import {useIsMounted} from '@englex/react-hooks/lib/useIsMounted';

import Icon from 'components/Icon';

import './PointerButton.scss';

const messages = defineMessages({
  Point: {
    id: 'LessonPage.PointerButton.Title'
  }
});

interface Props {
  onClick: () => Promise<unknown>;
}

export function PointerButton({onClick}: Props) {
  const intl = useIntl();

  const isMounted = useIsMounted();

  const [loading, setLoading] = useState(false);

  const stopPropagation = useCallback((event: React.MouseEvent<Button>) => {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
  }, []);

  const onClickHandler = useCallback(
    (event: React.MouseEvent<Button>) => {
      stopPropagation(event);
      setLoading(true);

      onClick().finally(() => {
        if (isMounted.current) {
          setLoading(false);
        }
      });
    },
    [isMounted, onClick, stopPropagation]
  );

  return (
    <Button
      className="pointer-button"
      bsStyle="success"
      disabled={loading}
      onClick={onClickHandler}
      onMouseDown={stopPropagation}
      onMouseUp={stopPropagation}
      title={intl.formatMessage(messages.Point)}
    >
      <Icon
        tag="i"
        className="pointer-button__icon"
        name={loading ? 'refresh' : 'virc-target'}
        spin={loading}
      />
      <FormattedMessage id="LessonPage.PointerButton.Title">
        {txt => <span className="pointer-button__text">{txt}</span>}
      </FormattedMessage>
    </Button>
  );
}
