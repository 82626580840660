import React from 'react';

import {PointerTooltip} from '../common/PointerTooltip';
import {SelectionButton} from './SelectionButton';

const trigger: string[] = [];

const align = {
  offset: [0, -5]
};

interface Props {
  exerciseId: string;
  getTooltipContainer: () => HTMLElement;
  children: React.ReactElement;
}

export const SelectionTooltip: React.FC<Props> = React.memo(({exerciseId, ...rest}) => {
  return (
    <PointerTooltip
      visible={true}
      trigger={trigger}
      overlayClassName="x-player-pointer-tooltip"
      overlay={<SelectionButton exerciseId={exerciseId} />}
      align={align}
      {...rest}
    />
  );
});
