import classNames from 'classnames';
import React from 'react';
import {injectIntl, type WrappedComponentProps} from 'react-intl';

import './Count.scss';

interface OwnProps {
  count: number;
  message: JSX.Element;
  maxCount?: number;
}

type Props = OwnProps & WrappedComponentProps;

class Count extends React.Component<Props> {
  public render() {
    const {count, message, maxCount = undefined} = this.props;

    const limiterIsValid = maxCount !== undefined;
    const exceeding = limiterIsValid && count > maxCount;

    return <div className={classNames('slate-word-count', {exceeding})}>{message}</div>;
  }
}

export default injectIntl(Count);
