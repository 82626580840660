import type React from 'react';
import {Block, type Editor, type Next} from '@englex/slate';
import {
  type Editor as ReactEditor,
  type EventHook,
  getEventRange,
  getEventTransfer
} from '@englex/slate-react';

import isShortcut from 'helpers/shortcut';
import {validateImageFile} from 'common/ImageUpload/functions';
import md5Chunk from 'services/common-methods/md5chunk';
import {UploadingPictureStatus} from 'common/ImageUpload/interface';

import ImageModal from './Modal/index';
import ToolbarButton from '../../ToolbarButton';
import {ButtonType, type ToolbarPlugin} from '../../interface';
import {buttonTitle} from '../i18n';
import {insertImage} from './changes';
import {isImageBlock} from '../../../../utils';
import {getToolBox, type ImageBlock, SlateBlock} from '../../../../interface';
import messages from './i18n';
import {getFurthestListBlockOfBlock} from '../List/utils';
import type ToolBox from '../../ToolBox';
import {IMAGE_MIN_HEIGHT} from './static';
import {type AfterDropInitials} from './interface';
import {imageTargetIsValid} from '../../ImageDropResolver';

class ImageButton extends ToolbarButton implements ToolbarPlugin {
  public type = ButtonType.IMAGE;
  public icon = 'image';
  public title = buttonTitle.Image;
  protected toggleChange: undefined;
  private nestedToolbarTitle = messages.replaceImage;
  private addBlockBeforeShortcut = 'enter';

  public handleClick = (e: React.MouseEvent<HTMLButtonElement>, editor: Editor) => {
    e.preventDefault();
    this.openImageModal(editor);
  };

  public isDisabled(editor: Editor) {
    if (super.isDisabled(editor)) {
      return true;
    }
    return !!editor.value.blocks.find(
      block => !!getFurthestListBlockOfBlock(editor.value.document, block!)
    );
  }

  public onKeyDown = (event: React.KeyboardEvent, change: ReactEditor & Editor, next: Next) => {
    const {value} = change;
    if (isShortcut(event, this.addBlockBeforeShortcut)) {
      if (value.selection?.isCollapsed && value.startBlock && isImageBlock(value.startBlock)) {
        const imgBlock: ImageBlock = value.startBlock;
        const previousSibling = value.document.getPreviousBlock(imgBlock.key);
        const block = Block.create(SlateBlock.DEFAULT);
        previousSibling
          ? // just insert block after previous sibling block of image
            change.moveToEndOfNode(previousSibling).insertBlock(block)
          : // insert as first document node
            change
              .moveToStartOfNode(imgBlock)
              .insertNodeByKey(value.document.key, 0, block)
              .moveToStartOfPreviousBlock();
        return;
      }
    }
    return next();
  };

  public onDrop: EventHook<React.DragEvent> = async (
    e,
    reactEditor: ReactEditor & Editor,
    next: Next
  ) => {
    let editor: Editor = reactEditor;

    if (!imageTargetIsValid(e, editor)) {
      return;
    }
    const eventTransfer = getEventTransfer(e);
    const eventRange = getEventRange(e, editor);
    if (eventTransfer.fragment) {
      return eventRange?.anchor.path ? next() : undefined;
    }
    if (eventRange?.isCollapsed) {
      editor = editor.select(eventRange);
    }
    if (eventTransfer.files.length === 1) {
      const file = eventTransfer.files[0];
      const md5 = await md5Chunk(file);
      const status = await validateImageFile(file, md5, undefined, undefined, IMAGE_MIN_HEIGHT);
      if (status && status !== UploadingPictureStatus.PREPARING) {
        this.openImageModal(editor, {status});
      } else {
        const fileReader = new FileReader();
        fileReader.onloadend = () => {
          const imageDataUrl = fileReader.result as string;
          if (imageDataUrl) {
            this.openImageModal(editor, {
              imageDataUrl,
              file,
              validatedFile: {file, md5}
            });
          }
        };
        fileReader.readAsDataURL(file);
      }
    }
  };

  protected getTitle = () => (this.toolbar ? this.nestedToolbarTitle : this.title);

  private insertImage = (editor: Editor, id: number, w: number, h: number) => {
    Promise.resolve().then(() => editor.command(insertImage, id, w, h));
  };

  private openImageModal = (editor: Editor, afterDropInitials?: AfterDropInitials) => {
    const toolbox = editor.query<ToolBox>(getToolBox);
    toolbox.open(
      ImageModal,
      {
        insertImage: (id: number, w: number, h: number) => this.insertImage(editor, id, w, h),
        openedFromInlineToolbar: !!this.toolbar,
        ...afterDropInitials
      },
      'dnd-image-modal'
    );
  };
}
export default ImageButton;
