import React from 'react';
import Tooltip, {type TooltipProps} from 'rc-tooltip/lib/Tooltip';

import './PointerTooltip.scss';

const destroyTooltipOnHide = {
  keepParent: false
};

export function PointerTooltip({children, placement, ...rest}: TooltipProps) {
  return (
    <Tooltip
      placement={placement || 'top'}
      mouseEnterDelay={0.1}
      destroyTooltipOnHide={destroyTooltipOnHide}
      {...rest}
    >
      {children}
    </Tooltip>
  );
}
