import {useCallback, useRef} from 'react';
import {useSelector, useStore} from 'react-redux';
import {defineMessages, useIntl} from 'react-intl';
import {useLocation, useParams} from 'react-router-dom';

import * as toastr from 'components/toastr';
import {publishingPointerTimeout} from 'config/static';
import {type AppState} from 'store/interface';

import {useAxiosDispatch} from '../../../hooks/redux/useAxiosDispatch';
import {urlOpenedPointer, publishPointerUrl, setPublishingPointer} from '../../../common/action';

const messages = defineMessages({
  TextSuccessToast: {
    id: 'Exercise.ShowSelectedText.SuccessToast'
  },
  TextFailToast: {
    id: 'Exercise.ShowSelectedText.FailToast'
  },
  ElementSuccessToast: {
    id: 'Exercise.ShowSelectedElement.SuccessToast'
  },
  ElementFailToast: {
    id: 'Exercise.ShowSelectedElement.FailToast'
  }
});

interface DataForRange {
  exerciseId: string;
  widgetId: string;
  editorId: string;
  range: object;
  elementId: string;
}

interface DataForElement {
  elementId: string;
}

type Data = DataForRange | DataForElement;

export enum MessageType {
  Selection,
  Element
}

const queue: MessageType[] = [];

export function usePublishPointer() {
  const intl = useIntl();

  const dispatch = useAxiosDispatch();

  const {pathname} = useLocation();
  const {courseId} = useParams<{courseId: string}>();

  const store = useStore<AppState>();

  const partnerSession = useSelector((state: AppState) => state.rtc.partnerSessionId);

  const timer = useRef<NodeJS.Timeout>();
  const prevPublishingPointer = useRef<boolean | undefined>(false);

  const clearTimer = useCallback(() => {
    if (timer.current) {
      clearTimeout(timer.current);
      timer.current = undefined;
    }

    queue.pop();

    if (queue.length === 0) {
      dispatch(urlOpenedPointer());
    }
  }, [dispatch]);

  const handleFail = useCallback(
    (type: MessageType) => {
      clearTimer();
      toastr.error(
        '',
        intl.formatMessage(
          type === MessageType.Selection ? messages.TextFailToast : messages.ElementFailToast
        )
      );
    },
    [clearTimer, intl]
  );

  const handleSuccess = useCallback(
    (type: MessageType) => {
      clearTimer();
      toastr.success(
        '',
        intl.formatMessage(
          type === MessageType.Selection ? messages.TextSuccessToast : messages.ElementSuccessToast
        )
      );
    },
    [clearTimer, intl]
  );

  return useCallback(
    (type: MessageType, data: Data, callback: Function) => {
      return new Promise((resolve, reject) => {
        if (timer.current || !partnerSession) return reject(false);

        queue.push(type);

        const url = `${pathname}?#x-element-${data.elementId}`;

        const action = publishPointerUrl({
          url,
          courseId: Number(courseId),
          partnerSession,
          ...data
        });

        const unsubscribe = store.subscribe(() => {
          const state: AppState = store.getState();
          const {publishingPointerUrl} = state.classroom!;

          if (prevPublishingPointer.current && !publishingPointerUrl && timer.current) {
            unsubscribe();
            handleSuccess(type);
            callback();
            return resolve(true);
          }

          prevPublishingPointer.current = publishingPointerUrl;
        });

        timer.current = setTimeout(() => {
          unsubscribe();
          handleFail(type);
          reject(false);
        }, publishingPointerTimeout);

        dispatch(setPublishingPointer());
        dispatch(action);
      });
    },
    [partnerSession, courseId, pathname, store, dispatch, handleFail, handleSuccess]
  );
}
