import {Block, type Editor} from '@englex/slate';

import {JustifyContentValue, SlateBlock} from '../../../../interface';

const defaultStyle = {justifyContent: JustifyContentValue.CENTER};

export const insertImage = (change: Editor, id: number, w: number, h: number) => {
  const imageBlockData = {
    type: SlateBlock.IMAGE,
    data: {id, width: w, height: h, style: defaultStyle}
  };
  if (change.value.selection?.isExpanded) {
    change.delete();
  } else if (change.value.blocks.size === 1) {
    const block = change.value.blocks.first();
    if (
      block.get('type') === SlateBlock.IMAGE ||
      (block.get('type') === SlateBlock.DEFAULT && !block.text.length)
    ) {
      return change.insertBlock(Block.create(imageBlockData)).removeNodeByKey(block.key);
    }
  }
  return change.insertBlock(imageBlockData);
};
