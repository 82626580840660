import React from 'react';
import {type Editor} from '@englex/slate';
import {injectIntl, type WrappedComponentProps} from 'react-intl';

import type ToolbarButton from '../../../../SlateEditor/plugins/ToolbarButton';

interface Props {
  editor: Editor;
  plugins: ToolbarButton[];
}

class InlineToolbar extends React.Component<Props & WrappedComponentProps, {}> {
  public render() {
    return (
      <div contentEditable={false} className="table-inline-toolbar">
        {this.props.plugins.map(this.renderPluginBtn)}
      </div>
    );
  }

  private renderPluginBtn = (plugin: ToolbarButton, key: number) => {
    const {editor, intl} = this.props;

    return plugin.renderToolbarButton({key, editor, intl});
  };
}

export default injectIntl(InlineToolbar);
