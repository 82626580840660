import {type FC} from 'react';
import {type RenderBlockProps} from '@englex/slate-react';

import {type RendererPlugin} from '../interface';
import {isBlockOfType} from '../../utils';
import {SlateBlock} from '../../interface';

import './Example.scss';

interface Props extends RenderBlockProps {
  className?: string;
}

const ExampleBlock: FC<Props> = ({className = 'slate-example-block', children, attributes}) => {
  return (
    <div {...attributes} className={className}>
      {children}
    </div>
  );
};

export const Example = (): RendererPlugin => {
  return {
    renderBlock: ({children, ...props}, editor, next) => {
      if (!isBlockOfType(props.node, SlateBlock.EXAMPLE)) {
        return next();
      }
      return <ExampleBlock {...props}>{children}</ExampleBlock>;
    }
  };
};
